import { UserRole } from './UserRole';
import { Locale, resolveLocaleByString } from '@ui/jug-ui';
import {
  CurrentUserProfileAPIModel,
  CurrentUserProfileContactAPIModel,
  CurrentUserProfileContactMeta,
  UserBoundCompanyAPIModel,
  UserCompanyMetaAPIModel
} from '../../services/api/models/user/CurrentUserProfileAPIModel';
import {
  DictionariableValueAPIModel,
  ExtendedDictionariableValueAPIModel
} from '../../services/api/models/dictionary/DictionariableValueAPIModel';
import { CityAPIModel } from '../../services/api/models/CityAPIModel';


export class CurrentUserDataModel {
  constructor(
    public id: number,
    public email: string,
    public roles: UserRole[],
    public companies: UserBoundCompanyAPIModel[],
    public lang: Locale,
    public passwordSet: boolean,

    public nickName: string,
    public birthDate: Date,

    public firstName: string,
    public familyName: string,
    public patronymicName: string,
    public gender: string,

    public primaryPhoneNumber: string,
    public secondaryPhoneNumber1: string,
    public secondaryEmail1: string,
    public secondaryEmail2: string,

    public company: ExtendedDictionariableValueAPIModel<UserCompanyMetaAPIModel>,
    public position: DictionariableValueAPIModel,

    public city: CityAPIModel,

    public interestTags: string[],

    public contacts?: CurrentUserProfileContactAPIModel[],
    public contactsMeta?: CurrentUserProfileContactMeta,

    public publicProfileToken?: string,
    public publicProfileUrl?: string,
    public publicProfileClosed?: boolean,
  ) {}
}


export function createCurrentUserDataModelFromAPIResponse(
  currentUserAPIModel: CurrentUserProfileAPIModel,
) {

  return new CurrentUserDataModel(
    currentUserAPIModel.id,
    currentUserAPIModel.email,
    currentUserAPIModel.roles
      ? currentUserAPIModel.roles.map(userTypeAsString => UserRole[userTypeAsString.toUpperCase()])
      : [],
    currentUserAPIModel.companies || [],
    resolveLocaleByString(currentUserAPIModel.lang),
    currentUserAPIModel.passwordSet,

    currentUserAPIModel.nickName || null,
    currentUserAPIModel.birthDate
      ? new Date(`${currentUserAPIModel.birthDate}T00:00:00`)
      : null,
    currentUserAPIModel.firstName,
    currentUserAPIModel.familyName,
    currentUserAPIModel.patronymicName,
    currentUserAPIModel.gender,
    currentUserAPIModel.primaryPhoneNumber,
    currentUserAPIModel.secondaryPhoneNumber1,
    currentUserAPIModel.secondaryEmail1,
    currentUserAPIModel.secondaryEmail2,

    currentUserAPIModel.company || {
      value: null,
      rawValue: null,
      meta: {
        rawCompanyUrl: null,
      }
    },
    currentUserAPIModel.position || {
      value: null,
      rawValue: null,
    },
    currentUserAPIModel.city,

    currentUserAPIModel.interestTags,

    currentUserAPIModel.contacts,
    currentUserAPIModel.contactsMeta,

    currentUserAPIModel.publicProfileToken,
    currentUserAPIModel.publicProfileUrl,
    currentUserAPIModel.publicProfileClosed,
  );
}
